import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"flat":"","color":"toolbar-action transparent"}},[_c(VTextField,{staticClass:"v-input--search",attrs:{"label":"","placeholder":"Search User","prepend-inner-icon":"mdi-magnify","filled":"","rounded":"","dense":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchAction.apply(null, arguments)},"click:clear":_vm.clearSearchAction},model:{value:(_vm.search.keyword),callback:function ($$v) {_vm.$set(_vm.search, "keyword", $$v)},expression:"search.keyword"}}),_c(VSpacer),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary v-btn--new","dark":"","to":"/users/new"}},[_vm._v("New")])],1),_c('Paginate',{attrs:{"meta":_vm.meta},on:{"paginate":_vm.paginateAction}}),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return _vm._l((item.user),function(subuser,index){return _c('span',{key:subuser.id},[_c('router-link',{staticClass:"base-link",attrs:{"to":("/users/" + (subuser.id))}},[_vm._v(_vm._s(subuser.title))]),(index != item.user.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()],1)})}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(item.updated_at))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VSpacer),(item.id != _vm.authUser.id && !item.isAdmin)?_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('ConfirmDialog',{attrs:{"title":"Confirm delete","description":"Are you sure you want to delete this item","okayLabel":"Yes","cancelLabel":"No","show":_vm.showDeleteConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }