<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-text-field
        v-model="search.keyword"
        label=""
        placeholder="Search User"
        prepend-inner-icon="mdi-magnify"
        filled
        rounded
        dense
        clearable
        class="v-input--search"
        @keydown.enter="searchAction"
        @click:clear="clearSearchAction"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="primary v-btn--new" dark class="ma-2" to="/users/new">New</v-btn>
    </v-toolbar>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />
    <v-data-table
      :headers="headers"
      :items="users"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.user="{ item }">
        <span v-for="(subuser, index) in item.user" :key="subuser.id">
          <router-link :to="`/users/${subuser.id}`" class="base-link">{{ subuser.title }}</router-link>
          <span v-if="index != item.user.length - 1">, </span>
        </span>
      </template>

      <template v-slot:item.updated_at="{ item }">
        {{ formatDateAndTime(item.updated_at) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ formatDateAndTime(item.created_at) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-spacer></v-spacer>
        <v-icon small @click.stop="deleteItem(item)" v-if="item.id != authUser.id && !item.isAdmin">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>
<style scoped>
.v-input--search {
  max-width: 80%;
}
.v-btn--new {
  position: absolute;
  top: -12px;
  right: 0;
}
</style>
<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import Paginate from '@/views/Paginate';
import { dateMixin } from '@/mixins/dateMixin';

export default {
  name: 'UsersView',
  components: {
    ConfirmDialog,
    Paginate
  },
  mixins: [dateMixin],
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('user/list', {
        page: currentPage,
        sort_by: 'id',
        sort_desc: true
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  methods: {
    load() {
      store.dispatch('user/list', {
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'surname',
        sort_desc: this.options.sortDesc[0] ?? false
      });
    },
    editItem(item) {
      this.$router.push({ path: `/users/${item.id}` });
    },
    deleteItem(item) {
      this.user_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('user/delete', this.user_id);
      this.load();
    },
    paginateAction(item) {
      if (item == 'next') {
        const currentPage = this.meta.current_page + 1;
        store.dispatch('user/list', currentPage);
      } else if (item == 'previous') {
        const currentPage = this.meta.current_page - 1;
        store.dispatch('user/list', currentPage);
      }
    },
    searchAction() {
      this.searchQuery({
        keyword: this.search.keyword,
        active: true,
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'surname',
        sort_desc: this.options.sortDesc[0] ?? false
      });
    },
    searchQuery(options) {
      Object.keys(options).forEach(key => {
        if (options[key] === null || options[key] === undefined) {
          delete options[key];
        }
      });
      store.dispatch('user/search', options);
      this.search = options;
      this.menu = false;
    },
    clearSearchAction() {
      this.load();
    }
  },
  computed: {
    ...mapGetters('user', ['loading', 'error', 'users', 'meta', 'links']),
    ...mapGetters('auth', ['authUser'])
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          if (this.search.active) {
            this.searchQuery(this.search);
          } else {
            this.load();
          }
        } else {
          this.options.init = true;
        }
      },
      deep: true
    }
  },
  data: () => ({
    showDeleteConfirmDialog: false,
    search: {
      keyword: '',
      active: false
    },
    options: {},
    headers: [
      { text: 'First Name', value: 'first_name' },
      { text: 'Surname', value: 'surname' },
      { text: 'Email', value: 'email' },
      { text: 'Modified', value: 'updated_at', align: 'right' },
      { text: 'Created', value: 'created_at', align: 'right' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
